<template>
  <v-container>

    <v-row class="justify-center">
      <v-col class="text-center">
        <img :src="require('@/assets/dome.png')" style="width: 50%;"/>
      </v-col>
    </v-row>
    <h1 class="text-center mb-4 mt-5">Informations Point de vente</h1>
    <v-sheet class="rounded-lg">
      <v-card-text class="px-5 py-5">
        <v-alert
          dense
          v-if="error"
          color="red"
          dark
          icon="mdi-information-outline"
          border="left"
          prominent
        >
          {{ error }}
        </v-alert>

        <form @submit.prevent="save">
          <v-row>
            <!--  category_id -->
            <v-col cols="12" md="6">
              <v-select
                outlined
                label="Catégorie du compte *"
                item-text="name"
                :loading="isCategoriesLoading"
                item-value="id"
                :items="categories"
                v-model="form.category_id"
                @change="categoryChanged()"
                hide-details=""
                :color="errors && errors.category_id ? 'red' : 'primary'"
                :class="errors && errors.category_id ? 'redBorder' : ''"
                @input="
                  errors && errors.category_id
                    ? (errors.category_id = '')
                    : false
                "
              >
                <template v-slot:item="slotProps">
                  <div class="d-flex align-center justify-center">
                    <div style="width: 50px">
                      <img
                        :src="getImageLink(slotProps.item.image)"
                        max-height="25"
                        max-width="25"
                        width="25"
                        height="25"
                        class="p-2"
                      />
                    </div>

                    {{ slotProps.item.name }}
                  </div>
                </template>
              </v-select>
              <p class="red--text">
                {{ errors.category_id ? errors.category_id[0] : "" }}
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <v-select
                outlined
                label="Type du magasin *"
                item-text="name"
                :loading="isTypesLoading"
                item-value="id"
                :items="types"
                v-model="form.salepoint_type_id"
                :disabled="!form.category_id"
                hide-details=""
                :color="errors && errors.salepoint_type_id ? 'red' : 'primary'"
                :class="errors && errors.salepoint_type_id ? 'redBorder' : ''"
                @input="
                  errors && errors.salepoint_type_id
                    ? (errors.salepoint_type_id = '')
                    : false
                "
              >
              </v-select>
              <p class="red--text">
                {{
                  errors.salepoint_type_id ? errors.salepoint_type_id[0] : ""
                }}
              </p>
            </v-col>

            <!--  pos_name -->
            <v-col cols="12" md="6">
              <!--  pos_name -->
              <v-text-field
                outlined
                prepend-inner-icon="mdi-store"
                type="text"
                v-model="form.pos_name"
                label="Nom du Magasin *"
                hide-details=""
                :color="errors && errors.pos_name ? 'red' : 'primary'"
                :class="errors && errors.pos_name ? 'redBorder' : ''"
                @input="
                  errors && errors.pos_name ? (errors.pos_name = '') : false
                "
              >
              </v-text-field>
              <p class="red--text">
                {{ errors.pos_name ? errors.pos_name[0] : "" }}
              </p>
              <!-- End pos_name -->
            </v-col>

            <!--  owner_name -->
            <v-col cols="12" md="6">
              <!--  owner_name -->
              <v-text-field
                outlined
                prepend-inner-icon="mdi-account-outline"
                type="text"
                v-model="form.owner_name"
                label="Nom du propriétaire *"
                hide-details=""
                :color="errors && errors.owner_name ? 'red' : 'primary'"
                :class="errors && errors.owner_name ? 'redBorder' : ''"
                @input="
                  errors && errors.owner_name ? (errors.owner_name = '') : false
                "
              >
              </v-text-field>
              <p class="red--text">
                {{ errors.owner_name ? errors.owner_name[0] : "" }}
              </p>
              <!-- End owner_name -->
            </v-col>

            <!-- Phone -->
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" md="3">
                  <v-select
                    outlined
                    label="Pays *"
                    item-text="name"
                    :loading="isCountriesLoading"
                    item-value="id"
                    :items="countries"
                    v-model="form.country_id"
                    @change="countryChanged"
                    hide-details=""
                    :color="errors && errors.country_id ? 'red' : 'primary'"
                    :class="errors && errors.country_id ? 'redBorder' : ''"
                    @input="
                      errors && errors.country_id
                        ? (errors.country_id = '')
                        : false
                    "
                  >
                    <template v-slot:item="slotProps">
                      <div class="d-flex align-center justify-center">
                        <div style="width: 50px">
                          <img
                            :src="getImageLink(slotProps.item.flag)"
                            max-height="25"
                            max-width="25"
                            width="25"
                            height="25"
                            class="p-2"
                          />
                        </div>

                        {{ slotProps.item.name }}
                      </div>
                    </template>
                  </v-select>
                  <p class="red--text">
                    {{ errors.country_id ? errors.country_id[0] : "" }}
                  </p>
                </v-col>
                <v-col cols="12" md="9">
                  <!--  owner_phone -->
                  <v-text-field
                    outlined
                    prepend-inner-icon="mdi-phone-outline"
                    type="phone"
                    v-model="form.owner_phone"
                    label="Téléphone *"
                    :prefix="phoneCode + ''"
                    hide-details=""
                    :color="errors && errors.owner_phone ? 'red' : 'primary'"
                    :class="errors && errors.owner_phone ? 'redBorder' : ''"
                    @input="
                      errors && errors.owner_phone
                        ? (errors.owner_phone = '')
                        : false
                    "
                  >
                  </v-text-field>
                  <p class="red--text">
                    {{ errors.owner_phone ? errors.owner_phone[0] : "" }}
                  </p>
                  <!-- End owner_phone -->
                </v-col>
              </v-row>
            </v-col>
            <!--  adress -->
            <v-col cols="12" md="6">
              <!--  adress -->
              <v-text-field
                outlined
                prepend-inner-icon="mdi-map-marker-outline"
                type="text"
                v-model="form.adress"
                label="Addresse *"
                hide-details=""
                :color="errors && errors.adress ? 'red' : 'primary'"
                :class="errors && errors.adress ? 'redBorder' : ''"
                @input="errors && errors.adress ? (errors.adress = '') : false"
              >
              </v-text-field>
              <p class="red--text">
                {{ errors.adress ? errors.adress[0] : "" }}
              </p>
            </v-col>

            <!--  wilaya -->
            <v-col cols="12" md="6">
              <!-- Wilaya -->
              <v-select
                outlined
                label="Wilaya *"
                item-text="name"
                :loading="isWilayasLoading"
                item-value="id"
                :items="wilayas"
                v-model="form.wilaya_id"
                hide-details=""
                :color="errors && errors.wilaya_id ? 'red' : 'primary'"
                :class="errors && errors.wilaya_id ? 'redBorder' : ''"
                @input="
                  errors && errors.wilaya_id ? (errors.wilaya_id = '') : false
                "
                @change="getCommunes"
              >
              </v-select>
              <p class="red--text">
                {{ errors.wilaya_id ? errors.wilaya_id[0] : "" }}
              </p>
            </v-col>

            <!--  Commune -->
            <v-col cols="12" md="6">
              <!-- Commune -->
              <v-select
                outlined
                label="Commune *"
                item-text="name"
                :loading="isCommunesLoading"
                item-value="id"
                :items="communes"
                v-model="form.commune_id"
                hide-details=""
                :color="errors && errors.commune_id ? 'red' : 'primary'"
                :class="errors && errors.commune_id ? 'redBorder' : ''"
                @input="
                  errors && errors.commune_id ? (errors.commune_id = '') : false
                "
              >
              </v-select>
              <p class="red--text">
                {{ errors.commune_id ? errors.commune_id[0] : "" }}
              </p>
            </v-col>

            <!--  latitude -->
            <v-col cols="12" md="6">
              <!--  latitude -->
              <v-text-field
                outlined
                prepend-inner-icon="mdi-map-marker-outline"
                type="text"
                v-model="form.latitude"
                label="Latitude *"
                hide-details=""
                :color="errors && errors.latitude ? 'red' : 'primary'"
                :class="errors && errors.latitude ? 'redBorder' : ''"
                @input="
                  errors && errors.latitude ? (errors.latitude = '') : false
                "
              >
              </v-text-field>
              <p class="red--text">
                {{ errors.latitude ? errors.latitude[0] : "" }}
              </p>
              <!-- End latitude -->
            </v-col>

            <!--  longitude -->
            <v-col cols="12" md="6">
              <!--  longitude -->
              <v-text-field
                outlined
                prepend-inner-icon="mdi-map-marker-outline"
                type="text"
                v-model="form.longitude"
                label="Longitude *"
                hide-details=""
                :color="errors && errors.longitude ? 'red' : 'primary'"
                :class="errors && errors.longitude ? 'redBorder' : ''"
                @input="
                  errors && errors.longitude ? (errors.longitude = '') : false
                "
              >
              </v-text-field>
              <p class="red--text">
                {{ errors.longitude ? errors.longitude[0] : "" }}
              </p>
              <!-- End longitude -->
            </v-col>
          </v-row>

          <v-btn class="primary rounded-lg" block large elevation="0" type="submit" :loading="btnLoading">
            Enregister
          </v-btn>
        </form>
      </v-card-text>
    </v-sheet>
  </v-container>
</template>

<script>
import { HTTP } from "@/http-common";
export default {
  data() {
    return {
      // Loadings
      btnLoading: false,
      isCategoriesLoading: false,
      isTypesLoading: false,
      isCountriesLoading: false,
      isCommunesLoading: false,
      isWilayasLoading: false,

      // Lists
      countries: [],
      categories: [],
      allTypes: [],
      types: [],
      wilayas: [],
      communes: [],

      phoneCode: "",

      error: "",
      errors: {},

      form: {
        // salepoint
        pos_name: "",
        owner_name: "",
        owner_phone: "",
        salepoint_type_id: "",

        // adresse
        country_id: "",
        wilaya_id: "",
        commune_id: "",
        adress: "",
        latitude: "",
        longitude: "",
      },
    };
  },
  methods: {
    save() {
      this.error = "";
      this.errors = {};
      this.btnLoading = true;
      HTTP.post("/v1/multistep/salepoint", {
        ...this.form,
        owner_phone: this.phoneCode + this.form.owner_phone
      })
        .then((res) => {
          this.btnLoading = false;

          this.$store.state.user.user.category_id = this.form.category_id;
          this.$store.state.user.user.country_id = this.form.country_id;
          this.$store.dispatch("setStore", res.data.data);
          //this.$store.user.user.salepoint = res.data.data;
          this.$router.push('/login');

        }).catch((err) => {
          this.btnLoading = false;
          this.error = "Une erreur s'est produite";
          if (err.response && err.response.data && err.response.data.errors) {
            this.errors = err.response.data.errors;
          }
        });
    },

    getCountries() {
      this.isCountriesLoading = true;
      HTTP.get(process.env.VUE_APP_BASE_URL + "v1/countries")
        .then((res) => {
          this.isCountriesLoading = false;
          this.countries = res.data.data;
        })
        .catch((err) => {
          this.isCountriesLoading = false;
          console.log(err);
        });
    },
    getCategories() {
     
      this.isCategoriesLoading = true;
      HTTP.get(process.env.VUE_APP_BASE_URL + "v1/users/categories")
        .then((res) => {
          this.isCategoriesLoading = false;
          this.categories = res.data.data;
          this.categoryChanged();
        })
        .catch((err) => {
          this.isCategoriesLoading = false;
          console.log(err);
        });
    },

    getWilayas() {
      this.isWilayasLoading = true;
      HTTP.get(process.env.VUE_APP_BASE_URL + "wilaya/all")
        .then((res) => {
          this.isWilayasLoading = false;
          this.wilayas = res.data;
        })
        .catch((err) => {
          this.isWilayasLoading = false;
          console.log(err);
        });
    },

    getCommunes() {
      this.isCommunesLoading = true;
      HTTP.get(
        process.env.VUE_APP_BASE_URL + "v1/commune/" + this.form.wilaya_id
      )
        .then((res) => {
          this.isCommunesLoading = false;
          this.communes = res.data;
          // console.log(res);
        })
        .catch((err) => {
          this.isCommunesLoading = false;
          console.log(err);
        });
    },

    getTypes() {
      this.isTypesLoading = true;
      HTTP.get(process.env.VUE_APP_BASE_URL + "salepoint-type")
        .then((res) => {
          this.isTypesLoading = false;
          // this.types = res.data;
          this.allTypes = res.data;
          this.categoryChanged();
        })
        .catch((err) => {
          this.isTypesLoading = false;
          console.log(err);
        });
    },

    countryChanged() {
      var id = this.form.country_id;
      var country = this.countries.find((x) => x.id === id);

      this.phoneCode = "+" + country.phonecode;
    },

    categoryChanged() {
      var category = this.form.category_id;
      this.types = this.allTypes.filter((element) => {
        return element.user_category_id == category;
      });
    },

    setData(){
      var user = this.$store.getters.getUser.user;
      var salepoint = user.salepoint;

      this.form.category_id = user.category_id;
      this.form.country_id = user.country_id;
      
      if(salepoint){
        this.form.id = salepoint.id;
        this.form.pos_name = salepoint.pos_name;
        this.form.salepoint_type_id = salepoint.salepoint_type_id;
        this.form.owner_name = salepoint.owner_name;
        this.form.owner_phone = salepoint.owner_phone.replace("+213", "");
        this.form.adress = salepoint.adress;
        this.form.wilaya_id = salepoint.wilaya_id;
        this.form.commune_id = salepoint.commune_id;
        this.form.longitude = salepoint.longitude;
        this.form.latitude = salepoint.latitude;

        this.getCommunes();

      }



    }
  },
  mounted() {
    this.getCountries();
    this.getCategories();
    this.getTypes();
    this.getWilayas();

    this.setData();
  },

  computed: {
    countryId(){
      return this.form.country_id;
    }
  },

  watch: {
    countryId(value){
      if(value == 1){
        this.phoneCode = "+213";
      }
    }
  }
};
</script>

<style scoped>
</style>